@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

#header {
  background-size: cover;
  background-position: center;
  padding: 150px 0;
  position: relative;
  min-height: 100vh;
}

#header .overlay {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.intro-text {
  color: #fff;
  text-align: center;
  padding-top: 50px;
  position: relative;
  z-index: 1;
}

.intro-text h1 {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 30px;
}

.intro-text p {
  font-size: 22px;
  margin-bottom: 40px;
}

#features {
  padding: 100px 0;
  background: #f6f6f6;
}

.feature-item {
  padding: 30px;
  margin-bottom: 30px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.feature-item i {
  font-size: 40px;
  margin-bottom: 20px;
  color: #5ca9fb;
}

.feature-item h3 {
  font-size: 22px;
  margin-bottom: 15px;
}

#about {
  padding: 100px 0;
}

.about-images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.about-images img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.about-images img:hover {
  transform: scale(1.05);
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.about-images img:first-child {
  grid-column: 1 / -1;
}

.about-text {
  padding: 30px;
}

.about-text h2 {
  font-size: 36px;
  margin-bottom: 25px;
}

.about-text h3 {
  font-size: 24px;
  margin: 30px 0 15px;
}

.about-text blockquote {
  font-style: italic;
  border-left: 4px solid #5ca9fb;
  padding-left: 20px;
  margin: 20px 0;
  font-size: 18px;
}

.section-title h2 {
  font-size: 36px;
  margin-bottom: 50px;
  position: relative;
}

.section-title h2::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -15px;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background: #5ca9fb;
}
